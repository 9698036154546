<template>
  <div>
    <header class="header">
      <div class="header-text">付款给{{ mchInfo.storeName }}</div>
      <div class="header-img">
        <img :src="mchInfo.storeIcon ? mchInfo.storeIcon : avatar" alt="" />
      </div>
    </header>
    <div class="money-box">
      <div class="money-title">付款金额</div>
      <div class="money-input" @click="keyboardShow = true">
        <!-- ￥字符 货币的符号-->
        <div class="money-symbol">
          <img src="../../assets/icon/S.svg" alt="" />
        </div>
        <!-- 手写输入框 -->
        <div class="input-c">
          <div class="input-c-div-1">{{ amount }}</div>
          <!-- 数字金额后边的光标 -->
          <div class="input-c-div" style="background: #1A53FF"></div>
        </div>
        <!-- 手写输入框的提示文字 -->
        <div v-show="!amount" class="placeholder">请输入金额</div>
      </div>
    </div>
    <div v-if="!customField" class="remark-k">
      <div class="remark">
        <div class="remark-hui" v-show="remark">{{ remark }}</div>
        <div @click="myDialogStateFn(false)">
          {{ remark ? "修改" : "添加备注" }}
        </div>
      </div>
    </div>
    <div v-else>
      <div class="label-box">
        <van-form ref="customForm" scroll-to-error>
          <div v-for="(item, index) in mchInfo.customFieldList" :key="item.fieldId">
            <!-- 文本框 -->
            <van-field
              v-if="item.fieldType == 'text'"
              :name="item.fieldId" :label="item.fieldName"
              v-model="formData[item.fieldId]"
              clickable
              clearable
              :type="item.fieldVerifyRule == 'email' || item.fieldVerifyRule == 'tel' || item.fieldVerifyRule == 'number' ? item.fieldVerifyRule : 'text'"
              :required="item.fieldVerify == 1 ? true : false"
              :readonly="item.readonly == 1 ? true : false"
              :placeholder="item.fieldDesc?item.fieldDesc:`请输入${item.fieldName}`"
              :border="false"
              :rules="[item.fieldVerify == 1 ? {required: true, message: item.tips} : {},item.fieldVerifyRule == 'email' ? {pattern: /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,message: '邮箱格式错误',}: item.fieldVerifyRule == 'tel'? {pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,message: '手机号码格式错误',}: item.fieldVerifyRule == 'number'? { pattern: /^[+-]?\d+(\.\d+)?$/, message: '数字格式错误' }: {}]"
            />
            <!-- 单选框 -->
            <van-field
              v-if="item.fieldType == 'radio'"
              :name="item.fieldId"
              :label="item.fieldName"
              clickable
              :border="false"
              :required="item.fieldVerify == 1 ? true : false"
              :readonly="item.readonly == 1 ? true : false"
              :rules="[item.fieldVerify == 1? { required: true, message: item.tips }: {}]"
            >
              <template #input>
                <van-radio-group
                  direction="horizontal"
                  v-model="formData[item.fieldId]"
                >
                  <van-radio
                    v-for="(items, index) in item.fieldValues.split(',')"
                    :key="index"
                    :name="items"
                    checked-color="#1A53FF"
                    >{{ items }}</van-radio
                  >
                </van-radio-group>
              </template>
            </van-field>
            <!-- 复选框 -->
            <van-field
              v-if="item.fieldType == 'checkbox'"
              :name="item.fieldId"
              :label="item.fieldName"
              clickable
              :border="false"
              :required="item.fieldVerify == 1 ? true : false"
              :readonly="item.readonly == 1 ? true : false"
              :rules="[item.fieldVerify == 1? { required: true, message: item.tips }: {}]"
            >
              <template #input>
                <van-checkbox-group
                  v-model="formData[item.fieldId]"
                  direction="horizontal"
                >
                  <van-checkbox
                    v-for="(items, indexs) in item.fieldValues.split(',')"
                    :key="indexs"
                    :name="items"
                    shape="square"
                    checked-color="#1A53FF"
                    >{{ items }}</van-checkbox
                  >
                </van-checkbox-group>
              </template>
            </van-field>
            <!-- 下拉选项 -->
            <van-field
              v-if="item.fieldType == 'select'"
              readonly
              is-link
              :border="false"
              :label="item.fieldName"
              v-model="formData[item.fieldId]"
              :name="item.fieldId"
              :placeholder="item.fieldDesc?item.fieldDesc:`请选择${item.fieldName}`"
              :required="item.fieldVerify == 1 ? true : false"
              :rules="[item.fieldVerify == 1? { required: true, message: item.tips }: {}]"
              @click="vantShow['show' + index] = true"
            />
            <van-popup
              v-if="item.fieldType == 'select'"
              v-model="vantShow['show' + index]"
              round
              position="bottom"
            >
              <van-picker
                show-toolbar
                :title="'请选择' + item.fieldName"
                :columns="item.fieldValues.split(',')"
                @cancel="vantShow['show' + index] = false"
                @confirm="(val) => {formData[item.fieldId] = val;vantShow['show' + index] = false;}"
              />
            </van-popup>
            <!-- 日期 -->
            <van-field
              v-if="item.fieldType == 'timer'"
              readonly
              is-link
              :border="false"
              :label="item.fieldName"
              :name="item.fieldId"
              v-model="formData[item.fieldId]"
              :placeholder="item.fieldDesc?item.fieldDesc:`请选择${item.fieldName}`"
              :required="item.fieldVerify == 1 ? true : false"
              :rules="[item.fieldVerify == 1? { required: true, message: item.tips }: {}]"
              @click="vantShow['show' + index] = true"
            />
            <!-- <van-calendar
            v-if="item.fieldType == 'timer'"
            v-model="vantShow['show' + index]"
            :show-confirm="false"
            :title="item.fieldName"
            @confirm="
              (time) => {
                vantShow['show' + index] = false;
                formData[index][item.fieldId] = formatDate(time);
              }
            "
            color="#1A53FF"
          /> -->
            <van-popup
              v-if="item.fieldType == 'timer'"
              v-model="vantShow['show' + index]"
              round
              position="bottom"
            >
              <van-datetime-picker
                type="date"
                :title="item.fieldDesc?item.fieldDesc:`请选择${item.fieldName}`"
                :min-date="minDate"
                v-model="currentDate"
                @cancel="vantShow['show' + index] = false"
                @confirm="(date) => {vantShow['show' + index] = false;formData[item.fieldId] = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;}"
              />
            </van-popup>
            <!-- 级联选择 -->
            <van-field
              v-if="item.fieldType == 'cascader'"
              is-link
              readonly
              :border="false"
              :label="item.fieldName"
              :name="item.fieldId"
              v-model="formData[item.fieldId]"
              :required="item.fieldVerify == 1 ? true : false"
              :rules="[item.fieldVerify == 1? { required: true, message: item.tips }: {}]"
              :placeholder="item.fieldDesc?item.fieldDesc:`请选择${item.fieldName}`"
              @click="vantShow['show' + index] = true"
            />
            <van-popup
              v-if="item.fieldType == 'cascader'"
              v-model="vantShow['show' + index]"
              round
              position="bottom"
            >
              <van-cascader
                active-color="#1A53FF"
                :title="item.fieldDesc?item.fieldDesc:`请选择${item.fieldName}`"
                :options="JSON.parse(item.fieldValues)"
                :field-names="{text: 'label',value: 'value',children: 'children',}"
                @close="vantShow['show' + index] = false"
                @finish="({ selectedOptions }) => {vantShow['show' + index] = false;formData[item.fieldId] = selectedOptions.map((option) => option.label).join('/');}"
              />
            </van-popup>
          </div>
        </van-form>
      </div>
      <div style="margin: 11px">
        <van-button
          type="info"
          block
          @click="payment"
          :disabled="!amount"
          :loading="payLoading"
          loading-text="请稍后..."
          >支付</van-button>
      </div>
    </div>
    <!-- dialog 对话框 目前不需要添加备注，隐藏-->
    <MyDialog
      v-show="myDialogState"
      @myDialogStateFn="myDialogStateFn"
      :remark="remark"
    >
    </MyDialog>

    <!-- 键盘板块 目前不需要键盘 隐藏 -->
    <!-- <div class="keyboard-plus" v-show="keyboardState">
      <Keyboard
        @delTheAmount="delTheAmount"
        @conceal="conceal"
        @enterTheAmount="enterTheAmount"
        @payment="payment"
        :money="amount"
      ></Keyboard>
    </div> -->

    <van-number-keyboard
      :show="keyboardShow"
      theme="custom"
      extra-key="."
      title="@通联支付"
      :close-button-text="customField ? '确定' : '支付'"
      @blur="customField ? (keyboardShow = false) : (keyboardShow = true)"
      @input="onInputAmount"
      @delete="onDeleteAmount"
      :close-button-loading="payLoading"
      @close="customField || payLoading ? null : payment()"
    />
  </div>
</template>

<script>
import MyDialog from "@/views/dialog/dialog"; // 添加备注弹出的对话框
// import Keyboard from "@/views/keyboard/keyboard";  // 手写键盘
import {
  getPayPackage,
  getPayOrderInfo,
  getQrInfo,
  getQrUnifiedOrder,
} from "@/api/api";
import config from "@/config";
export default {
  // 注册备注对话框，和 手写键盘组件，由于这里是直接掉起支付事件，所以目前不应用
  components: { MyDialog },
  data: function () {
    return {
      avatar: require("../../assets/icon/wx.svg"), // 商户头像默认
      amount: "", // 支付金额默认
      resData: {},
      wxImg: require("../../assets/icon/wx.svg"), // 微信支付图片
      payOrderInfo: {}, //订单信息
      mchInfo: {}, //商户信息
      payType: "wx",
      remark: "",
      inter: 8, //整数位
      decimal: 2, //小数位
      keyboardState: false,
      myDialogState: false, //手写键盘dialog
      keyboardShow: true, //键盘状态
      vantShow: {}, //组件显示
      formData: {}, //表单数据
      fieldVerify: [], //必填选项
      customField: false, //是否自定义标签
      minDate: new Date(1970, 1, 1),
      currentDate: new Date(),
      payLoading: false, //请求中
    };
  },

  computed: {
    fieldComp() {
      return function (val, val1) {
        let arr1 = val.split(",");
        let arr2 = val1.split(",");

        const zip = ([x, ...xs], [y, ...ys]) => {
          if (x === undefined || y === undefined) return [];
          else return [[x, y], ...zip(xs, ys)];
        };
        let result = zip(arr1, arr2).map(([name, value]) => ({ name, value }));

        return result;
      };
    },
  },

  mounted() {
    const that = this;
    getQrInfo(that.$route.params.jeepayToken)
      .then((res) => {
        const fieldList = res.customFieldList;
        if (
          fieldList == undefined ||
          fieldList == null ||
          fieldList.length <= 0
        )
          that.customField = false;
        else that.customField = true;
        fieldList.forEach((item, index) => {
          if (
            item.fieldType == "select" ||
            item.fieldType == "timer" ||
            item.fieldType == "cascader"
          ) {
            that.$set(that.vantShow, "show" + index, false);
          }
          if (item.fieldVerify == 1) {
            that.fieldVerify.push(item.fieldId);
          }
          // that.formData.push({ [item.fieldId]: undefined });
        });

        that.mchInfo = res;
      })
      .catch((res) => {
        that.$router.push({
          name: config.errorPageRouteName,
          params: { errInfo: res.msg },
        });
      });
    // this.setPayOrderInfo(true); //获取订单信息 & 调起支付插件
  },

  created() {},

  methods: {
    myDialogStateFn(state, item) {
      const that = this;
      if (!that.myDialogState) {
        that.myDialogState = true;
      } else {
        that.myDialogState = false;
      }
      if (state) that.remark = item;
    },
    onInputAmount(item) {
      const that = this;
      let oldValue = that.amount;
      that.amount += item;
      if (!that.passCheck(that.amount)) {
        that.amount = oldValue;
        return;
      }
    },
    passCheck(val) {
      /*验证规则*/
      let aRules = [this.illegalInput, this.illegalValue, this.accuracy];
      return aRules.every((item) => {
        return item(val);
      });
    },
    illegalInput(val) {
      let aIllegal = [
        "00",
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "0..",
        ".",
      ];
      if (aIllegal.indexOf(val) > -1) {
        return false;
      }
      return true;
    },
    /*非法值*/
    illegalValue(val) {
      if (parseFloat(val) != val) {
        return false;
      }
      return true;
    },
    /*验证精度*/
    accuracy(val) {
      let v = val.split(".");
      if (v[0].length > this.inter) {
        return false;
      }
      if (v[1] && v[1].length > this.decimal) {
        return false;
      }
      return true;
    },
    onDeleteAmount() {
      this.amount = this.amount.slice(0, -1);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    conceal() {
      this.keyboardState = false;
    },
    payment() {
      const that = this;
      if (!that.amount || that.amount <= 0) {
        that.$toast("请输入正确金额");
        return;
      }
      
      const params = {
        amount: that.amount,
        mchNo: that.mchInfo.mchNo,
        body: that.remark,
        token: that.$route.params.jeepayToken
      };

      if (that.customField) {
        //自定义标签
        that.$refs.customForm.validate(that.fieldVerify).then(() => {
          /* params.customFieldJson = that.formData.filter(
            (t) => JSON.stringify(t) !== "{}"
          ); */
          params.customFieldJson = that.formData;
          that.createQrUnifiedOrder(params);
        });
      } else {
        that.createQrUnifiedOrder(params);
      }
    },
    createQrUnifiedOrder(params) {
      this.payLoading = true;
      getQrUnifiedOrder(params)
        .then((res) => {
          location.href = res.payData;
          this.payLoading = false;
        })
        .catch((re) => {
          this.$toast(re.msg);
          this.payLoading = false;
        });
    },
    setPayOrderInfo(isAutoPay) {
      const that = this;
      getPayOrderInfo()
        .then((res) => {
          that.payOrderInfo = res;

          if (isAutoPay) {
            that.pay();
          }
        })
        .catch((res) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: res.msg },
          });
        });
    },

    // 支付事件
    pay: function () {
      // 该函数执行效果慢
      let that = this;
      getPayPackage(this.amount)
        .then((res) => {
          if (res.code != "0") {
            return alert(res.msg);
          }

          if (res.data.orderState != 1) {
            //订单不是支付中，说明订单异常
            return alert(res.data.errMsg);
          }

          that.resData = res.data;
          if (typeof WeixinJSBridge == "undefined") {
            if (document.addEventListener) {
              document.addEventListener(
                "WeixinJSBridgeReady",
                that.onBridgeReady,
                false
              );
            } else if (document.attachEvent) {
              document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady);
              document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady);
            }
          } else {
            that.onBridgeReady();
          }
        })
        .catch((res) => {
          that.$router.push({
            name: config.errorPageRouteName,
            params: { errInfo: res.msg },
          });
        });
    },

    /* 唤醒微信支付*/
    onBridgeReady() {
      let that = this;

      // eslint-disable-next-line no-undef
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        JSON.parse(that.resData.payInfo),
        function (res) {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            // //重定向
            if (that.payOrderInfo.returnUrl) {
              location.href = that.payOrderInfo.returnUrl;
            } else {
              alert("支付成功！");
              window.WeixinJSBridge.call("closeWindow");
            }
          }
          if (res.err_msg == "get_brand_wcpay_request:cancel") {
            alert("支付取消");
            window.WeixinJSBridge.call("closeWindow");
          }
          if (res.err_msg == "get_brand_wcpay_request:fail") {
            alert("支付失败:" + JSON.stringify(res));
            window.WeixinJSBridge.call("closeWindow");
          }
          if (res.err_msg == "total_fee") {
            alert("缺少参数");
            window.WeixinJSBridge.call("closeWindow");
          }
        }
      );
    },
  },
};
</script>
<style lang="css">
@font-face {
  font-family: "wxFirstFont";
  src: url("../../assets/wx-zt/WeChatSansSS-Bold.ttf"); /* IE9 */
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
* {
  box-sizing: border-box;
}

.van-number-keyboard__title {
  font-size: 32px;
}

body {
  background-color: #ededed;
}
.header {
  box-sizing: border-box;
  display: flex;
  height: 80px;
  margin: 50px 40px;
  justify-content: space-between;
  align-items: center;
}

.header-text {
  height: 50px;
  line-height: 50px;
  text-align: left;
  font-size: 36px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  color: #323232;
  letter-spacing: 1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.header-img {
  flex-shrink: 0;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  overflow: hidden;
}
.header-img img {
  width: 100%;
  height: 100%;
}

.money-box {
  background: #fff;
  border-radius: 10px;
  margin: 20px;
  padding: 35px;
}

.money-title {
  text-align: left;
}
.money-input {
  display: flex;
  flex-shrink: 0;
  justify-content: safe;
  /* margin: 0 20px; */
  /* border-bottom: 1px solid #cccccc; */
  /* background-color: #fff; */
  /* width: calc(100vw - 140px); */
  /* min-width: 160px; */
  /* height: 180px; */
}
.money-input .money-symbol {
  position: relative;
  width: 30px;
  height: 40px;
  margin: 15px 15px 15px 0;
}
.money-input .money-symbol img {
  position: absolute;
  top: 15px;
  left: 0;
  width: 100%;
  height: 100%;
  /* margin-top: 10px; */
}
.money-input input {
  height: 98px;
  width: 90%;
  font-size: 70px;
  font-family: wxFirstFont SC, PingFang SC, PingFang SC-Medium;
  font-weight: 800;
  text-align: left;
  color: #000000;
  letter-spacing: 2px;
  border: none;
  outline: none;
  /* background-color: #ededed; */
  background-color: rgba(220, 20, 60, 0);
}

.label-box {
  background: #fff;
  padding: 5px;
  border-radius: 10px;
  margin: 20px;
}

.input-c {
  display: flex;
  align-items: center;

  height: 98px;
  /* width: 40px; */
  font-size: 70px;
  font-family: wxFirstFont SC, PingFang SC, PingFang SC-Medium;
  font-weight: 800;
  text-align: center;
  color: #000000;
  letter-spacing: 2px;
  border: none;
  outline: none;
  background-color: #ededed00;
  /* background-color: rgb(255, 7, 57); */
}
.input-c-div-1 {
  flex-shrink: 0;
  /* width: 36px; */
  padding-left: 2px;
  text-align: center;
  /* width: auto; */
}
.input-c-div {
  position: relative;
  flex-shrink: 0;
  margin-left: 5px;
  margin-right: 5px;
  width: auto;
  height: 66px;
  /* background: #07c160; */
  animation: blink 1s linear infinite;
  border: 1px solid #1A53FF;
}

.placeholder {
  color: #6b6b6b;
  font-weight: 400;
  text-align: left;
  padding-top: 4px;
  font-size: 60px;
}
.remark-k {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #1A53FF;
  letter-spacing: 1px;
}
.remark {
  display: flex;
  flex-flow: row;
}
.remark-hui {
  width: auto;
  height: 36px;
  font-size: 25px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  text-align: left;
  color: #808080;
  letter-spacing: 1px;
  margin-right: 30px;
}
.money-input input::-webkit-input-placeholder {
  /* placeholder颜色  */
  color: #6b6b6b;
  font-weight: 400;
  /* placeholder字体大小  */
  /* font-size: 12px; */
  /* placeholder位置  */
  text-align: left;
}
</style>